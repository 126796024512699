import { Box } from "@chakra-ui/react";
import React from "react";

export const NewBadge = () => (
  <Box
    as="span"
    color={"white"}
    backgroundColor={"brand.main"}
    borderRadius={"40px"}
    ml={"8px"}
    padding={"0px 8px"}
  >
    New
  </Box>
);
