import { ComponentWithAs, Flex, IconProps } from "@chakra-ui/react";
import { managerDashboardRoutes } from "@verivest/shared-components";
import React from "react";
import { buildRouteUrl } from "../../../utils/utils";
import {
  MenuAccountsIcon,
  MenuContactsIcon,
  MenuHomeIcon,
  MenuInvestmentsIcon,
  MenuOfferingsIcon,
  MenuOpportunitiesIcon,
  MenuPortalSettingsIcon,
  MenuProfileIcon,
  MenuImportIcon,
  MenuReportsIcon,
} from "../../general";
import { NewBadge } from "./NewBadge";
import { FeatureName } from "@verivest/shared-components/src/feature-toggle";

export enum ManagerDashboardMenuItems {
  Home = "home",
  Profile = "profile",
  Contacts = "contacts",
  Accounts = "accounts",
  Investments = "investments",
  Opportunities = "opportunities",
  Offerings = "offerings",
  Imports = "imports",
  Reports = "reports",
  Settings = "settings",
}

export const buildDashboardMenuItemDataCy = (item: ManagerDashboardMenuItems) =>
  `manager-dashboard-menu-item-${item}`;

export const buildDashboardPageDataCy = (item: ManagerDashboardMenuItems) =>
  `manager-dashboard-page-${item}`;

interface ManagerDashboardMenuItem {
  nextRelease?: boolean;
  name: ManagerDashboardMenuItems;
  icon: ComponentWithAs<"svg", IconProps>;
  to: string;
  children: React.ReactNode;
  featureFlag?: FeatureName;
}

const menuItems: ManagerDashboardMenuItem[] = [
  {
    name: ManagerDashboardMenuItems.Home,
    icon: MenuHomeIcon,
    to: managerDashboardRoutes.HOME,
    children: "Home",
  },
  {
    name: ManagerDashboardMenuItems.Profile,
    icon: MenuProfileIcon,
    to: managerDashboardRoutes.PROFILE,
    children: "Profile",
  },
  {
    name: ManagerDashboardMenuItems.Contacts,
    icon: MenuContactsIcon,
    to: managerDashboardRoutes.CONTACTS,
    children: (
      <Flex display={"flex"} alignItems={"center"}>
        Contacts <NewBadge />
      </Flex>
    ),
  },
  {
    name: ManagerDashboardMenuItems.Accounts,
    icon: MenuAccountsIcon,
    to: managerDashboardRoutes.ACCOUNTS,
    children: "Accounts",
  },
  {
    name: ManagerDashboardMenuItems.Investments,
    icon: MenuInvestmentsIcon,
    to: managerDashboardRoutes.INVESTMENTS,
    children: "Investments",
  },
  {
    name: ManagerDashboardMenuItems.Opportunities,
    icon: MenuOpportunitiesIcon,
    to: managerDashboardRoutes.OPPORTUNITIES,
    children: "Opportunities",
  },
  {
    name: ManagerDashboardMenuItems.Offerings,
    icon: MenuOfferingsIcon,
    to: managerDashboardRoutes.OFFERINGS,
    children: "Offerings",
  },
  {
    name: ManagerDashboardMenuItems.Reports,
    icon: MenuReportsIcon,
    to: buildRouteUrl(managerDashboardRoutes.REPORTS),
    children: "Reports",
  },
  {
    name: ManagerDashboardMenuItems.Imports,
    icon: MenuImportIcon,
    to: buildRouteUrl(managerDashboardRoutes.IMPORTS),
    children: "Imports",
    featureFlag: FeatureName.Importer,
  },
  {
    name: ManagerDashboardMenuItems.Settings,
    icon: MenuPortalSettingsIcon,
    to: managerDashboardRoutes.PORTAL_SETTINGS,
    children: "Settings",
  },
];

export const managerDashboardMenuItems = menuItems.filter((item) => {
  return !item.nextRelease;
});
